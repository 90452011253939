<template>
  <div>
    <!-- <header-nav :bg="bg" :text="text" /> -->
    <div class="yindao_iv" v-if="device !== 'true' && imgD !== 'ios'">
      <img class="xiantou" src="../../asset/xiantou.png" alt="">
      <div class="text">
        <div class="text_h1">若已安装研小白英语APP</div>
        <div class="text_p1">1. 点击右上角 <span>···</span></div>
        <div class="text_p2">2. 选择在 <span>浏览器</span> 中打开</div>
      </div>
      <img class="yindao" src="../../asset/yindao.png" alt="">
    </div>
    <div v-else>
      <div class="xia_top">
        <!-- <div> -->
        <img src="../../../public/imgs/logo.jpg" alt="">
        <h2>小白英语App</h2>
        <p>考研英语75+刷题神器</p>
        <div class="btnsD" v-if="imgD == 'ios'" @click="xiazai_ios">
          <div>
            <img src="../../asset/IOS.png" alt="">
            下载APP
          </div>
		  <div class="xia" @click="xiazai_android">点击下载安卓app</div>
        </div>
        <div class="btnsD" v-else @click="xiazai_android">
          <div>
            <img src="../../asset/Android.png" alt="" />
            下载APP
          </div>
		  <div class="xia" @click="xiazai_ios">点击下载iOS版</div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import {
    Notify,
  } from 'vant';
  import HeaderNav from '../../components/HeaderNav'
  import Footer from '../../components/Footer'
  import bg from '../../assets/header-bg1.png'
  import https from '../../https'
  export default {
    components: {
      HeaderNav,
      Footer,
      [Notify.name]: Notify,
    },

    data() {
      return {
        bg,
        text: '研小白 - 考研数字化备考服务平台',
        device: "",
        imgD: "",
        id: "",
        browser: {},
        downloadUrl:"",
      }
    },
    created() {
      var thta = this;
      var storage = window.sessionStorage;
      var device = storage.getItem('device');
      this.id = this.$route.query.nid;
      console.log( "device",this.id,device);
      if (device) {
        this.device = device
      }
      
      var browser = {
        versions: function() {
          var u = navigator.userAgent;
          thta.nuser = u;
          // var app = navigator.appVersion;
          return {
            trident: u.indexOf('Trident') > -1,
            /*IE内核*/
            presto: u.indexOf('Presto') > -1,
            /*opera内核*/
            webKit: u.indexOf('AppleWebKit') > -1,
            /*苹果、谷歌内核*/
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
            /*火狐内核*/
            mobile: !!u.match(/AppleWebKit.*Mobile.*/),
            /*是否为移动终端*/
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            /*ios终端*/
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
            /*android终端或者uc浏览器*/
            iPhone: u.indexOf('iPhone') > -1,
            /*是否为iPhone或者QQHD浏览器*/
            iPad: u.indexOf('iPad') > -1,
            /*是否iPad*/
            webApp: u.indexOf('Safari') == -1,
            /*是否web应该程序，没有头部与底部*/
            souyue: u.indexOf('souyue') > -1,
            superapp: u.indexOf('superapp') > -1,
            weixin: u.toLowerCase().indexOf('micromessenger') > -1,
            Safari: u.indexOf('Safari') > -1
          };
        }(),
        language: (navigator.browserLanguage || navigator.language).toLowerCase()
      };

      if (browser.versions.ios) {
        this.find(1)
        this.imgD = "ios"
        console.log("ios")
        // ----------------------------------------------------------
        // window.location.href = "http://eng.yanxiaobai.com/#/download?"+ window.location.href.substr(window.location.href.indexOf('?') + 1)
        // ----------------------------------------------------------
        setTimeout(function() {
          // alert("执行下载")
          // ----------------------------------------------------------
          window.location.href = "https://apps.apple.com/cn/app/id1561898649"
          window.location.href = "https://apps.apple.com/cn/app/id1561898649"; //为什么要加两遍我下面会说到
          // ----------------------------------------------------------
          // alert("执行完成")
        }, 2000)
      } else {
        this.find(2)
        this.imgD = "android"
        console.log("android")
       // window.location.href = "http://eng.yanxiaobai.com/#/download?"+ window.location.href.substr(window.location.href.indexOf('?') + 1)
      }
    },
    mounted() {
    

    },
    methods: {
      find(e) {
        var that = this
        https.fetchGet('/api/app/update/find/'+e,{} ).then(res => {
          if (res.data && res.data.data) {
            console.log("find", res.data)
            that.downloadUrl = res.data.data.url
          }
        }).catch(err => {
          that.loading = false
          // Notify({
          //   type: 'warning',
          //   message: '当前人数过多，请稍后重试'
          // });
          console.log(err)
        })
      },
      xiazai_ios() {
        setTimeout(function() {
          // alert("执行下载")
          // ----------------------------------------------------------
          window.location.href = "https://apps.apple.com/cn/app/id1561898649"
          window.location.href = "https://apps.apple.com/cn/app/id1561898649"; //为什么要加两遍我下面会说到
          // ----------------------------------------------------------
          // alert("执行完成")
        }, 200)
        
      },
      xiazai_android() {
        if(this.downloadUrl){
           var src  = this.downloadUrl;
        }else{
         // Notify({
         //   type: 'warning',
         //   message: '获取下载地址失败,请稍后再试'
         // });
         return
        }
        // window.location.href = "taobao://**";
        // var src ='https://io.ikaoyaner.com/xbky%2Fapp-android-debug%2F2020-12-09%2Fc8ae136cb68e444eb2b70f9973a1126d%2Fikaoyaner_1209R_1.0.apk';
        
        var form = document.createElement('form');
        form.action = src;
        document.getElementsByTagName('body')[0].appendChild(form);
        form.submit()
      }
    }
  }
</script>

<style lang="less" scoped>
	.xia{
	  color: #aaa;
	  font-size: 14px;
	  margin: 18px 0;
	  font-weight: initial;
	  text-decoration:underline;
	}
  .yindao_iv {
    text-align: center;
  }

  .yindao {
    width: 300px;
    margin: 60px auto 0;
  }

  .text {
    margin: 40px 50px 0;

    // text-align: center;
    .text_h1 {
      font-size: 19px;
      font-weight: 500;
      color: #333;
    }

    >div+div {
      margin-top: 14px;
    }

    >div {
      text-align: left;
      color: #7A7A7A;
      font-weight: 400;
      font-size: 15px;

      >span {
        font-size: 17px;
        font-weight: bold;
        color: #333;
      }
    }
  }

  .xiantou {
    position: fixed;
    right: 15px;
    top: 18px;
    width: 171px;
    height: 75px;
  }

  .xia_top {
    // display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 100px;

    // >div{
    text-align: center;

    >p {
      color: #666;
      font-size: 17px;
      margin: 20px 0 0;
      line-height: 24px;
    }

    >img {
      width: 66px;
      height: 66px;
      margin: 0 auto;
      border: 1px solid #DCDCDC;
      border-radius: 15px;
      display: block;
    }

    h2 {
      font-size: 22px;
      font-weight: bold;
      margin: 10px 0 0;
    }

    // }
    .btnsD {
      width: 260px;
      height: 44px;
      background-color: #3F94FF;
      color: #fff;
      border-radius: 4px;
      margin-top: 10px;
      margin: 50px auto 0;
      font-size: 18px;

      >div {
        width: 260px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
          width: 20px;
          // height: 26px;
          margin: 0 6px;
        }
      }


    }

  }

  .h1 {
    margin-top: 73px;
    text-align: center;
    font-size: 26px;
    color: #333333;
  }

  .h3 {
    margin-top: 14px;
    text-align: center;
    font-size: 22px;
    color: #595959;
  }

  .screenshot-wrap {
    margin-top: 48px;
    margin-bottom: 80px;
    text-align: center;

    .screenshot {
      width: 240px;
      margin-right: 20px;
      box-shadow: 0 0 20px 0 #eeeeee;
    }

    img {
      margin-top: 20px;
    }
  }

  .notes {
    // width: 100%;
    padding-bottom: 60px;
    background: #FBFBFB;
    overflow: hidden;
    padding: 0 50px;
    box-sizing: border-box;

    .h1 {
      margin-bottom: 48px;
    }

    .h2 {
      // width: 882px;
      margin: 0 auto;
      margin-bottom: 22px;
      color: #595959;
      font-size: 22px;
      line-height: 35px;
    }

    .ul {
      width: 882px;
      margin: 0 auto;
      margin-top: -16px;
      margin-bottom: 50px;
      color: #595959;
      font-size: 16px;
      line-height: 28px;

      >li {
        margin-left: 20px;
      }
    }
  }

  .contact-wrap {
    display: flex;
    justify-content: space-between;
    width: 485px;
    margin: 62px auto 127px auto;

    .contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >span {
        margin-top: 6px;
        font-size: 16px;
        color: #595959;
      }
    }
  }

  @media screen and (max-width:500px) {
    .notes {
      .h2 {
        width: 350px;
      }

      .ul {
        margin-left: -28px;
        width: auto;
      }
    }

    .contact-wrap {
      width: 100%;
      padding: 0 12px;
      box-sizing: border-box;

      .contact {
        margin-right: 0;
      }
    }
  }
</style>
